import Link from "next/link";
import { useCountdown } from "../../../hooks/useCountdown";
import css from "./style.module.scss";
import { useEffect, useState } from "react";

interface IStreamDisruptorProps {
  title: string;
  subtitle: string;
  disruptorStreamSource: string;
  streamStart: number;
}

export interface IStreamConfig extends IStreamDisruptorProps {
  showDisruptor: boolean;
  streamSource: string;
  chatSource: string;
  showChat: boolean;
}

export const StreamDisruptor = ({
  title,
  subtitle,
  disruptorStreamSource,
  streamStart,
}: IStreamDisruptorProps) => {
  const [collapseDisruptor, setCollapseDisruptor] = useState(false);
  const [delayDisruptor, setDelayDisruptor] = useState(true);
  const [days, hours, minutes, seconds] = useCountdown(new Date(streamStart));

  // temoporary hack until config-service is extended to handle alerts

  const isMeetingLink = disruptorStreamSource.includes("meeting.kita.bayern");
  const disruptorDestination = isMeetingLink
    ? disruptorStreamSource
    : "/livestream";

  useEffect(() => {
    setTimeout(() => setDelayDisruptor(false), 1000);
  }, []);

  const countDown = `${
    hours + days * 24
  } Stunden ${minutes} Minuten ${seconds} Sekunden`;
  const showTeaser = days + hours + minutes + seconds > 0;

  if (delayDisruptor) return <div></div>;
  else if (collapseDisruptor)
    return (
      <div
        className={css["collapsed-banner"]}
        onClick={() => setCollapseDisruptor(false)}
      >
        Livestream
      </div>
    );

  return (
    <div className={css["main-container"]}>
      <div className={css["stream-container"]}>
        <div className={css["stream-teaser-container"]}>
          <div
            className={css["collapse-button"]}
            onClick={() => setCollapseDisruptor(true)}
          ></div>

          {showTeaser && (
            <div className={css["teaser"]}>
              <span className={css["teaser-title"]}>Stream startet in</span>

              <span className={css["teaser-countdown"]}>{countDown}</span>

              <div className={css["teaser-image"]}></div>
            </div>
          )}

          {!showTeaser && <div className={css["live-banner"]}>Live</div>}

          {/* hack for meeting link */}
          {!showTeaser && !isMeetingLink && (
            <iframe src={disruptorStreamSource} />
          )}
          {!showTeaser && isMeetingLink && (
            <div className={css["teaser-image"]}></div>
          )}
        </div>
      </div>
      <div className={css["description-container"]}>
        <div className={css["description-text"]}>
          <span className={css["title"]}>{title}</span>
          <span className={css["subtitle"]}>{subtitle}</span>
        </div>
        <Link href={disruptorDestination}>
          <div className={css["expand-button"]}></div>
        </Link>
      </div>
    </div>
  );
};
