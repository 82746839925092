import React from "react";
import css from "./style.module.scss";
import { DefaultSlider, DefaultSliderProps } from "../../common/Slider/Slider";

interface IImageCarousel {
  imageList: string[];
  initialIndex: number;
  onCardClick: (card: PixelProgram) => void;
}

export const ImageCarousel = ({
  imageList,
  initialIndex,
  onCardClick,
}: IImageCarousel) => {
  const ArrowNext = (props: any) => (
    <div className={css["next-button"]} onClick={props.onClick}></div>
  );

  const ArrowPrev = (props: any) => (
    <div className={css["prev-button"]} onClick={props.onClick}></div>
  );

  const settings: DefaultSliderProps = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1860,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 2460,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className={css["main-layout"]}>
      <DefaultSlider {...settings}>
        {imageList.map((img) => (
          <div className={css["slider-wrapper"]} key={img}>
            <img className={css["carousel-image"]} src={img} />
          </div>
        ))}
      </DefaultSlider>
    </div>
  );
};
