import css from "./style.module.scss";

interface IProgramCard {
  title: string;
  day: number;
  month: string;
  color: string;
  onCardClick: () => void;
}

export const ProgramCard = ({
  title,
  day,
  month,
  color,
  onCardClick,
}: IProgramCard) => {
  return (
    <div className={css["main-layout"]} onClick={onCardClick}>
      <div className={css["calendar"]} style={{ backgroundColor: color }}>
        <span className={css["day"]}>{day}</span>
        <span className={css["month"]}>{month}</span>
      </div>
      <span className={css["title"]}>{title}</span>
      <button
        className={css["modal-button"]}
        style={{ backgroundColor: "#fff", color: color }}
      >
        Infos und Anmeldung
      </button>
    </div>
  );
};
