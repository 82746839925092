import { ReactNode, useRef } from "react";
import { useHoverDirty, useMeasure } from "react-use";
import css from "./style.module.scss";

export const Themenwelt = ({
  welt,
}: {
  welt: {
    title: string;
    dividerColor: string;
    pic: string;
    content: ReactNode;
  };
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const isHovered = useHoverDirty(ref);

  const [contentRef, { x, y, width, height, top, right, bottom, left }] =
    useMeasure<HTMLDivElement>();

  return (
    <div ref={ref} className={css["root"]}>
      <div ref={contentRef}>
        <div
          className={css["title"]}
          style={{ color: isHovered ? welt.dividerColor : undefined }}
        >
          {welt.title}
        </div>

        <div
          className={css["divider"]}
          style={{ background: welt.dividerColor }}
        />
      </div>

      <div
        style={{
          width: "100%",
          minHeight: 150,
          position: "relative",
          height: `calc(100% - ${height}px)`,
        }}
      >
        <img
          className={css["pic"]}
          src={`/images/pixelwerkstatt/themenwelten/new/${welt.pic}.svg`}
          alt=""
        />
        <div className={css["content"]}>
          <div>{welt.content}</div>
          {/* Hide Button for now, until we actually have that information */}
          {/* <button
            className={css["to-themenwelt"]}
            style={{ background: welt.dividerColor }}
          >
            {/* Zur Themenwelt *
            Mehr Infos folgen
          </button> */}
        </div>
      </div>
    </div>
  );
};
