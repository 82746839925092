import { FormEvent, useState } from "react";

export const useSubmitForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSubmit = (evt: FormEvent<HTMLFormElement>, timeTaken: number) => {
    evt.preventDefault();
    const data = new FormData(evt.target as any);

    const jsonData = JSON.stringify({
      ...Object.fromEntries(data),
      timeTaken,
    });

    setSubmitting(true);

    fetch("/api/pixelwerkstatt-submit", {
      method: "POST",
      body: jsonData,
    })
      .then((res) => {
        if (!res.ok) {
          setHasError(true);
        } else {
          setSubmitSuccessful(true);
        }
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return { submitting, submitSuccessful, onSubmit, hasError };
};
