import css from "./style.module.scss";
import { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";

export const Modal = ({
  children,
  show,
  onClose,
  className,
  title,
  width = "600px",
}: {
  className?: string;
  children: ReactNode;
  show: boolean;
  onClose: VoidFunction;
  title: string;
  width?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showContent, setShowContent] = useState(show);
  const bottomElement = useRef<HTMLDivElement>(null);

  const closing = () => {
    document.body.classList.remove("modal-open");
    onClose();
  };

  if (show) document.body.classList.add("modal-open");

  useEffect(() => {
    if (show) {
      setShowContent(true);
      ref.current?.focus();
    } else {
      setTimeout(() => {
        setShowContent(false);
      }, 250);
    }
  }, [show]);

  return (
    <div
      id="open-modal"
      className={classNames(className, css["modal-window"], {
        [css["show"]]: show,
      })}
      onMouseDown={(e) => {
        closing();
      }}
    >
      <div
        className={css["modal-wrapper"]}
        style={{ width }}
        ref={ref}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <header>{title}</header>
        <div
          title="Modal schließen"
          onClick={() => {
            closing();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              closing();
            }
          }}
          tabIndex={0}
          className={css["modal-close"]}
        >
          Schließen
        </div>
        <div className={css["content"]}>{showContent && children}</div>
        <div ref={bottomElement}>&nbsp;</div>
      </div>
    </div>
  );
};
