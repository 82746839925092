export const themenWelten = [
  {
    title: "Filmarbeit &\n Kameratricks",
    pic: "F&K–Grafik",
    dividerColor: "#A1DBA3",
    content:
      "Lerne, wie du mit Kindern zu ihren Themen z.B. einen kreativen Stop-Motion-Film erstellen kannst und wie ihr gemeinsam das Vorgehen reflektiert. Tauche ein in Green-Screen-Techniken oder produziere einen Film mit eigenen Fotos, selbstgestalteten Hauptfiguren und Hintergründen.",
  },
  {
    title: "Fotografie &\n Bildbearbeitung",
    dividerColor: "#75C5BF",
    pic: "F+B–Grafik",
    content:
      "Kinder lieben es, mit Fotos eigene Perspektiven, ihre Umwelt und Geschichten festzuhalten. Bei uns kannst du lernen, wie du daraus mit ihnen gemeinsam kreative Bildungsaktivitäten gestalten kannst, oder wie manipulierte Bilder entstehen und auf uns wirken und wie Du mit den Kindern darüber sprechen kannst.",
  },
  {
    title: "Auditive \n Medien",
    dividerColor: "#ECCF08",
    pic: "AM–Grafik",
    content:
      "Bei uns erfährst du, wie du mit Kindern Hör- und Musikbeiträge aufnehmen, gestalten, bearbeiten und spielerisch nutzen kannst und wie diese Aktivitäten für die Kinder selbstwirksam werden.",
  },
  {
    title: "Programmierung &\n Robotik",
    dividerColor: "#F39D74",
    pic: "P+R–Grafik",
    content:
      "Erfahre, wie du bereits mit Kindern die Funktionsweise und den Nutzen von Computern und Robotern auf spielerische Art und Weise mit kindgerechten Lernrobotern und Programmier-Apps erschließen und reflektieren kannst.",
  },
  {
    title: "Forschen \n mit Medien",
    dividerColor: "#CD9CD1",
    content:
      "Lerne, wie du mit Tablets, Apps und Zubehör den Forscherdrang von Kindern unterstützen und das Forschen mit digitalen Medien mit den Kindern reflektieren kannst.",
    pic: "FmM–Medien",
  },
  {
    title: "Creative\nMaking",
    dividerColor: "#98BCFF",
    pic: "CM–Grafik",
    content:
      "Selbermachen und gestalten mit einfacher Elektronik, aber auch digitalen Technologien, lautet hier das Motto. Ob Malroboter, Treppenklavier oder heißer Draht – tüftle mit Kindern an eigenen Ideen und tausche dich darüber mit ihnen aus.",
  },
  {
    title: "Digitale \n Spiele",
    dividerColor: "#D5AD85",
    pic: "DS–Grafik",
    content:
      "Faszination Spielewelt – setze dich mit digitalen Spielen der Kinder auseinander, greife sie in der pädagogischen Praxis auf, indem Du sie mit Kindern zum Thema machst und mit ihnen auch einfache Anwendungen selbst programmierst.",
  },
];
