import React from "react";
import css from "./style.module.scss";
import { ProgramCard } from "./ProgramCard/ProgramCard";
import { getMonthNameShort } from "../../../utils/date";
import { DefaultSlider, DefaultSliderProps } from "../../common/Slider/Slider";

interface IProgramCarousel {
  programCards: PixelProgram[];
  initialIndex: number;
  onCardClick: (card: PixelProgram) => void;
}

const getEventColor = (eventType: string) => {
  switch (eventType) {
    case "WS":
      return "#7DB942";
    case "FT":
      return "#F39D74";
    case "VT":
      return "#72BEB7";
    default:
      return "#696871";
  }
};

export const ProgramCarousel = ({
  programCards,
  initialIndex,
  onCardClick,
}: IProgramCarousel) => {
  const ArrowNext = (props: any) => (
    <div className={css["next-button"]} onClick={props.onClick}></div>
  );

  const ArrowPrev = (props: any) => (
    <div className={css["prev-button"]} onClick={props.onClick}></div>
  );

  const settings: DefaultSliderProps = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: initialIndex,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className={css["main-layout"]}>
      <DefaultSlider {...settings}>
        {programCards.map((card) => (
          <div className={css["slide-wrapper"]} key={card.title + card.url}>
            <ProgramCard
              title={card.titleShort}
              day={card.startDate.getDate()}
              month={getMonthNameShort(card.startDate.getMonth() + 1)}
              color={getEventColor(card.type)}
              onCardClick={() => onCardClick(card)}
            />
          </div>
        ))}
      </DefaultSlider>
    </div>
  );

  // HINT: this code uses swiper which is nicer than slick-carousel but stopped working with nextJS-14
  // if swiper comes back to life soon than use it again, otherwise delete that section

  // import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
  // import "swiper/swiper-bundle.min.css";
  // import "swiper/css";
  // export const ProgramCarousel = ({
  //   programCards,
  //   initialIndex,
  //   onCardClick,
  // }: IProgramCarousel) => {
  //   const swiperRef = useRef<SwiperClass>();

  //   return (
  //     <div className={css["main-layout"]}>
  //       <div
  //         className={css["prev-button"]}
  //         onClick={() => {
  //           if (swiperRef.current) swiperRef.current.slidePrev();
  //         }}></div>
  //       <div
  //         className={css["next-button"]}
  //         onClick={() => {
  //           if (swiperRef.current) swiperRef.current.slideNext();
  //         }}></div>
  //       <Swiper
  //         spaceBetween={24}
  //         slidesPerView={4}
  //         breakpoints={{
  //           0: {
  //             slidesPerView: 1,
  //           },
  //           768: {
  //             slidesPerView: 2,
  //           },
  //           1200: {
  //             slidesPerView: 3,
  //           },
  //           1750: {
  //             slidesPerView: 4,
  //           },
  //         }}
  //         allowTouchMove={true}
  //         initialSlide={initialIndex}
  //         onSwiper={(swiper: any) => {
  //           swiperRef.current = swiper;
  //         }}>
  //         {programCards.map((card) => (
  //           <SwiperSlide key={card.startDate + card.titleShort}>
  //             <ProgramCard
  //               title={card.titleShort}
  //               day={card.startDate.getDate()}
  //               month={getMonthNameShort(card.startDate.getMonth() + 1)}
  //               color={getEventColor(card.type)}
  //               onCardClick={() => onCardClick(card)}
  //             />
  //           </SwiperSlide>
  //         ))}
  //       </Swiper>
  //     </div>
  //   );
};
