import { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { IDayCareItem } from "../../../types/Daycare";
import css from "./style.module.scss";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import classNames from "classnames";
import { Building } from "react-bootstrap-icons";
import { useLogin } from "../../NavBar/NavBar";

const formatAddress = (item?: IDayCareItem) => {
  if (!item) {
    return "";
  }

  return `${item.street_address}, ${item.postal_code} ${item.location}`;
};

const placeholderSearch = "Suche nach Deiner Kita";

export const ContactForm = ({
  onSubmit,
  submitSuccessful,
  isSubmitting,
  submitFailed,
}: {
  onSubmit: (evt: FormEvent<HTMLFormElement>, timeTaken: number) => void;
  submitSuccessful?: boolean;
  isSubmitting: boolean;
  submitFailed: boolean;
}) => {
  const [institutionName, setInstitutionName] = useState("");

  const lastSearch = useRef("");

  const [optionsAreLoading, setOptionsAreLoading] = useState(false);
  const [options, setOptions] = useState<IDayCareItem[]>([]);
  const [validationError, setValidationError] = useState("");

  const firstTyping = useRef<Date>();

  const { tokenParsed } = useLogin();

  useEffect(() => {
    if (tokenParsed) {
      try {
        document.querySelector<HTMLInputElement>('[name="firstName"]')!.value =
          tokenParsed.given_name;
        document.querySelector<HTMLInputElement>('[name="lastName"]')!.value =
          tokenParsed.family_name;
        document.querySelector<HTMLInputElement>('[name="email"]')!.value =
          tokenParsed.email;
      } catch (ex) {
        console.log(ex);
      }
    }
  }, [tokenParsed]);

  useEffect(() => {
    const listener = () => {
      if (!firstTyping.current) {
        firstTyping.current = new Date();
      }
    };

    const inputs = document.querySelector("form")?.querySelectorAll("input");

    inputs?.forEach((input) => {
      input.addEventListener("change", listener);
    });

    return () => {
      inputs?.forEach((input) => {
        input.removeEventListener("change", listener);
      });
    };
  }, []);

  const institutionAddress = institutionName
    ? options.find((opt) => opt.name === institutionName)
    : undefined;

  return (
    <form
      className={css["form"]}
      onSubmit={(evt) => {
        const kitaValue = document.querySelector<HTMLInputElement>(
          `[placeholder="${placeholderSearch}"]`
        )?.value;

        setInstitutionName(kitaValue as string);

        evt.preventDefault();

        setTimeout(() => {
          onSubmit(
            evt,
            new Date().getTime() - (firstTyping.current?.getTime() ?? 0)
          );
        }, 0);
      }}
    >
      <div className="row">
        <div className="col-12">
          <label className={classNames("w-100", css["floating-input"])}>
            <span>
              Kita/Institution
              <span className={css["required"]}>*</span>
            </span>

            <AsyncTypeahead
              id="institution-name"
              isLoading={false}
              onChange={(selected) => {
                if (selected.length > 0) {
                  setInstitutionName((selected[0] as IDayCareItem).name);
                }
              }}
              renderMenuItemChildren={(option, { text }, index) => {
                const fetchedOption = option as unknown as IDayCareItem;
                return (
                  <Fragment>
                    <div>
                      <Building className={css["icon"]} />
                    </div>
                    <div>
                      <span>{fetchedOption.name}</span>
                      <div className={css["address-line"]}>
                        <small>
                          {fetchedOption.street_address} ·{" "}
                          {fetchedOption.postal_code} {fetchedOption.location}
                        </small>
                      </div>
                    </div>
                  </Fragment>
                );
              }}
              filterBy={() => true}
              labelKey="name"
              onSearch={(query) => {
                lastSearch.current = query;
                setOptionsAreLoading(true);

                fetch(`/api/daycare-search?q=${query}`)
                  .then((resp) => resp.json())
                  .then((json) => {
                    if (lastSearch.current === query) {
                      setOptions(json);
                      setOptionsAreLoading(false);
                    }
                  });
              }}
              onInputChange={() => {
                if (validationError) {
                  setValidationError("");
                }
              }}
              className={css["typeahead"]}
              options={options}
              emptyLabel="Keine Kitas gefunden."
              placeholder={placeholderSearch}
            />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {validationError && (
            <div className={classNames("fw-bold", css["validation-warning"])}>
              {validationError}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label className="w-100">
            Vorname<span className={css["required"]}>*</span>
            <br />
            <input type="text" required name="firstName" className="w-100" />
          </label>
        </div>
        <div className="col-6">
          <label className="w-100">
            Nachname<span className={css["required"]}>*</span>
            <br />
            <input type="text" required name="lastName" className="w-100" />
          </label>
        </div>
      </div>
      {/* Virtual fields start */}
      <input
        readOnly
        type="text"
        name="institutionName"
        value={institutionName ?? ""}
        style={{ display: "none" }}
      />
      <input
        readOnly
        type="text"
        name="institutionAddress"
        value={formatAddress(institutionAddress)}
        style={{ display: "none" }}
      />
      {/* Virtual fields end */}
      <div className="row">
        <div className="col-6">
          <label className="w-100">
            Anliegen<span className={css["required"]}>*</span>
            <br />
            <select
              name="topic"
              className="w-100"
              required
              defaultValue={"Bitte auswählen..."}
            >
              <option disabled hidden>
                Bitte auswählen...
              </option>
              <option>Teamtag</option>
              <option>Workshop</option>
              <option>Fortbildung</option>
              <option>Veranstaltung</option>
              <option>Fragen</option>
              <option>Wünsche/Anregungen</option>
              <option>Sonstiges</option>
            </select>
          </label>
        </div>
        <div className="col-6">
          <label className="w-100">
            Betreff<span className={css["required"]}>*</span>
            <br />
            <input type="text" required name="subject" className="w-100" />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <label className="w-100">
            E-Mail<span className={css["required"]}>*</span>
            <br />
            <input type="email" required name="email" className="w-100" />
          </label>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <label className="w-100">
            Deine Anfrage an uns<span className={css["required"]}>*</span>
            <br />
            <textarea required name="requestText" className="w-100" />
          </label>
        </div>
      </div>

      <input
        type="text"
        className={css["hny"]}
        name="submit_password"
        tabIndex={-1}
        autoComplete="off"
      />

      <input
        type="checkbox"
        name="contact_by_fax"
        className={css["hny"]}
        value="1"
        tabIndex={-1}
        autoComplete="off"
      />

      {submitFailed && (
        <div className={classNames("fw-bold mt-3 mb-2", css["submit-failed"])}>
          ❌{"  "}Es ist ein Fehler aufgetreten. Wurden alle erforderlichen
          Felder ausgefüllt?
        </div>
      )}

      {submitSuccessful ? (
        <div className={classNames("fw-bold mt-3 mb-2", css["submit-success"])}>
          ✅{"  "}Deine Anfrage wurde erfolgreich abgeschickt.
        </div>
      ) : (
        <button type="submit">
          {isSubmitting ? "Einen Augenblick bitte..." : "Absenden"}
        </button>
      )}
    </form>
  );
};
