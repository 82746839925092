import { Modal } from "../../../common/Modal/Modal";
import css from "./style.module.scss";

interface IProgramModal {
  program: PixelProgram | null;
  onClose: () => void;
}

export const ProgramModal = ({ program, onClose }: IProgramModal) => {
  if (!program) return <></>;

  const [
    startDate,
    endDate,
    badgeSourceEventType,
    badgeSourceHybrid,
    badgeSourceCatering,
  ] = prepareModelData(program);

  return (
    <Modal
      className={css["modal"]}
      show={!!program}
      title={program.title || ""}
      onClose={onClose}
      width="800px"
    >
      <div className={css["program-modal-wrapper"]}>
        <b className={css["program-modal-subtitle"]}>{program.titleLong}</b>
        <div className={css["program-modal-date"]}>
          <b>Beginn: </b>
          <span>{startDate}</span>

          <b>Ende: </b>
          <span>{endDate}</span>

          {program.liveStreamTime && (
            <>
              <b>Livestream: </b>
              <span>{program.liveStreamTime}</span>
            </>
          )}
        </div>

        {program.description &&
          program.description.split("<br>").map((t) => <div key={t}>{t}</div>)}

        {program.referee && (
          <div className={css["program-modal-referee"]}>
            <span className={css["program-modal-referee-label"]}>
              <b>Referierende (m/w/d):</b>
            </span>
            {program.referee.split("<br>").map((t) => (
              <span className={css["program-modal-referee-value"]}>
                {" "}
                {t} <br />
              </span>
            ))}
          </div>
        )}

        {program.price && (
          <span>
            <b>Kosten:</b> {program.price}
          </span>
        )}

        <div className={css["bottom-wrapper"]}>
          {buildBottomContent(program)}
          <div className={css["badge-wrapper"]}>
            {badgeSourceEventType && (
              <img className={css["badge"]} src={badgeSourceEventType} alt="" />
            )}

            {badgeSourceCatering && (
              <img className={css["badge"]} src={badgeSourceCatering} alt="" />
            )}

            {badgeSourceHybrid && (
              <img className={css["badge"]} src={badgeSourceHybrid} alt="" />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

function prepareModelData(program: PixelProgram) {
  const startDate =
    program.startDate.toLocaleDateString([], {
      hour: "2-digit",
      minute: "2-digit",
    }) || "";
  const endDate =
    program.endDate.toLocaleDateString([], {
      hour: "2-digit",
      minute: "2-digit",
    }) || "";

  let badgeSourceEventType = "/images/pixelwerkstatt/program/";
  let badgeSourceCatering = "/images/pixelwerkstatt/program/";
  let badgeSourceHybrid = "/images/pixelwerkstatt/program/";

  switch (program.type) {
    case "VT":
      badgeSourceEventType += "vortrag.svg";
      break;
    case "FT":
      badgeSourceEventType += "fachtag.svg";
      break;
    case "WS":
      badgeSourceEventType += "workshop.svg";
      break;
    default:
      badgeSourceEventType = "";
  }

  switch (program.features) {
    case "online":
      badgeSourceHybrid += "online.svg";
      badgeSourceCatering = "";
      break;
    case "hybrid":
      badgeSourceHybrid += "hybrid.svg";
      badgeSourceCatering += "getraenk.svg";
      break;
    case "catering":
      badgeSourceCatering += "snack.svg";
      badgeSourceHybrid = "";
      break;
    case "hybrid+catering":
      badgeSourceHybrid += "hybrid.svg";
      badgeSourceCatering += "snack.svg";
      break;
    default:
      badgeSourceHybrid = "";
      badgeSourceCatering = "";
  }
  return [
    startDate,
    endDate,
    badgeSourceEventType,
    badgeSourceHybrid,
    badgeSourceCatering,
  ];
}

function buildBottomContent(program: PixelProgram) {
  if (program.features === "online") {
    return (
      <div>
        <p className={css["program-modal-alternative"]}>
          Den Livestream findest du am Tag der Veranstaltung auf unserer
          Homepage{" "}
          <a
            href={"https://www.pixelwerkstatt.kita.bayern"}
            rel="noopener noreferrer"
            target="_blank"
          >
            www.pixelwerkstatt.kita.bayern
          </a>
          .
        </p>
      </div>
    );
  } else {
    if (!program.showApplyButton) return <div></div>;

    return (
      <a href={program.url || ""} rel="noopener noreferrer" target="_blank">
        <button className={css["program-modal-link"]}>Anmelden</button>
      </a>
    );
  }
}
